<!-- 功能模块（菜单）管理 -->
<template>
  <div>
    <a-card title="主菜单列表">
      <template #extra>
        <a-button type="primary" size="small" @click.stop="doAddMainMenu">
          <PlusOutlined />
          添加主菜单
        </a-button>
      </template>
      <a-collapse accordion v-model:activeKey="activeKey" @change="onChange">
        <a-collapse-panel
          :key="menu.id.toString()"
          :header="menu.name"
          v-for="menu in mainMenuList"
        >
          <a-card title="子菜单列表">
            <template #extra>
              <a-button
                type="primary"
                size="small"
                @click.stop="doAddSubMenu(menu)"
              >
                <PlusOutlined />
                添加子菜单
              </a-button>
            </template>
            <a-table
              :rowKey="(record) => record.id"
              :pagination="false"
              class="ant-table-striped"
              size="middle"
              bordered
              :columns="columns"
              :data-source="subMenuList"
              :rowClassName="
                (record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <template #keepAlive="{ record }">
                <a-switch
                  size="small"
                  checkedChildren="开启"
                  unCheckedChildren="关闭"
                  v-model:checked="record.keepAlive"
                  @change="doSetCache(record)"
                />
              </template>
              <template #status="{ record }">
                <a-switch
                  size="small"
                  checkedChildren="可用"
                  unCheckedChildren="禁用"
                  v-model:checked="record.enable"
                  @change="doSetStatus(record)"
                />
              </template>
              <template #action="{ record }">
                <span>
                  <a-button type="link" size="small" doDeleteMenu>
                    <DeleteOutlined />删除
                  </a-button>
                  <a-divider
                    type="vertical"
                    style="border-color: #7cb305"
                    dashed
                  />
                  <a-button
                    type="link"
                    size="small"
                    @click.stop="doEditSubMenu(menu, record)"
                  >
                    <EditOutlined />编辑
                  </a-button>
                  <a-divider
                    type="vertical"
                    style="border-color: #7cb305"
                    dashed
                  />
                  <a-button
                    type="link"
                    size="small"
                    @click.stop="doDetailSubMenu(menu, record)"
                  >
                    <FileTextOutlined />详情
                  </a-button>
                </span>
              </template>
            </a-table>
          </a-card>
          <template #extra>
            <a-button type="link" size="small" @click.stop="doDeleteMenu(menu)">
              <DeleteOutlined />删除
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click.stop="doEditMainMenu(menu)"
            >
              <EditOutlined />编辑
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click.stop="doDetailMainMenu(menu)"
            >
              <FileTextOutlined />详情
            </a-button>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </a-card>
  </div>
  <div>
    <MenuForm
      :modalVisible="modalVisible"
      :title="formTitle"
      :isCreate="isCreate"
      :editData="editData"
      :mainInfo="mainInfo"
      @handleModalVisible="handleModalVisible"
      @initMainMenuList="initMainMenuList"
      @initSubMenuList="initSubMenuList"
    />
  </div>
</template>

<script>
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import { reactive, toRefs, onMounted, createVNode } from "vue";
import {
  getMainMenuList,
  getSubMenuList,
  updateMenuCache,
  updateMenuStatus,
  deleteMenu,
} from "@/api/menu";
import MenuForm from "./components/MenuForm.vue";
export default {
  name: "Menu",
  components: {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    FileTextOutlined,
    MenuForm,
  },
  setup() {
    const state = reactive({
      formTitle: "",
      isCreate: null, //0新增  1编辑  2详情
      modalVisible: false,
      activeKey: "", //折叠面板打开的面板（角色）
      mainMenuList: [], //主菜单列表
      subMenuList: [], //子菜单列表
      selectedRowKeys: [],
      mainInfo: {},
      editData: {
        parentId: null,
        id: null,
        name: "",
        menuCode: "",
        linkUrl: "",
        iconUrl: "",
        path: "",
        sort: null,
        memo: "",
        enable: true,
        keepAlive: true,
      },
      columns: [
        {
          title: "菜单名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "菜单编码",
          dataIndex: "menuCode",
          key: "menuCode",
        },
        {
          title: "菜单路径",
          dataIndex: "linkUrl",
          key: "linkUrl",
        },
        {
          title: "路由",
          dataIndex: "path",
          key: "path",
        },
        {
          title: "页面缓存",
          align: "center",
          dataIndex: "keepAlive",
          key: "keepAlive",
          slots: {
            customRender: "keepAlive",
          },
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "enable",
          key: "enable",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "操作",
          align: "center",
          key: "action",
          slots: {
            customRender: "action",
          },
        },
      ],
    });
    onMounted(() => {
      initMainMenuList();
    });
    const onChange = (key) => {
      if (key) {
        initSubMenuList(key);
        state.activeKey = key;
      }
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const doSetCache = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将更新菜单【" + record.name + "】的页面缓存功能，是否继续？"
        ),
        onOk() {
          updateMenuCache({ mid: record.id, val: record.keepAlive }).then(
            (resp) => {
              if (resp) {
                initSubMenuList(state.activeKey);
              }
            }
          );
        },
        onCancel() {
          message.info("已取消菜单状态更新操作！！！");
          initSubMenuList(state.activeKey);
        },
      });
    };
    const doSetStatus = (record) => {
      console.log(record.enable);
      console.log(record.id);
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将更新菜单【" + record.name + "】的状态，是否继续？"
        ),
        onOk() {
          updateMenuStatus({ mid: record.id, val: record.enable }).then(
            (resp) => {
              if (resp) {
                initSubMenuList(state.activeKey);
              }
            }
          );
        },
        onCancel() {
          message.info("已取消菜单状态更新操作！！！");
          initSubMenuList(state.activeKey);
        },
      });
    };
    const initMainMenuList = () => {
      getMainMenuList().then((resp) => {
        if (resp) {
          state.mainMenuList = resp;
        }
      });
    };
    const initSubMenuList = (mid) => {
      getSubMenuList(mid).then((resp) => {
        if (resp) {
          state.subMenuList = resp;
        }
      });
    };
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };
    const doAddMainMenu = () => {
      state.formTitle = "添加主菜单";
      state.isCreate = 1;
      state.modalVisible = true;
      state.editData = {
        parentId: 0,
      };
    };
    const doAddSubMenu = (mainMenu) => {
      state.formTitle = "添加子菜单";
      state.isCreate = 4;
      state.modalVisible = true;
      state.mainInfo = {
        mid: mainMenu.id,
        name: mainMenu.name,
      };
      state.editData = {
        parentId: mainMenu.id,
      };
    };
    const doDetailMainMenu = (mainMenu) => {
      state.formTitle = "主菜单详情";
      state.isCreate = 3;
      state.modalVisible = true;
      state.editData = {
        name: mainMenu.name,
        menuCode: mainMenu.menuCode,
        linkUrl: mainMenu.linkUrl,
        iconUrl: mainMenu.iconUrl,
        path: mainMenu.path,
        sort: mainMenu.sort,
        memo: mainMenu.memo,
        enable: mainMenu.enable,
        keepAlive: mainMenu.keepAlive,
      };
    };
    const doDetailSubMenu = (mainMenu, subMenu) => {
      state.formTitle = "子菜单详情";
      state.isCreate = 6;
      state.modalVisible = true;
      state.mainInfo = {
        mid: mainMenu.id,
        name: mainMenu.name,
      };
      state.editData = {
        name: subMenu.name,
        menuCode: subMenu.menuCode,
        linkUrl: subMenu.linkUrl,
        iconUrl: subMenu.iconUrl,
        path: subMenu.path,
        sort: subMenu.sort,
        memo: subMenu.memo,
        enable: subMenu.enable,
        keepAlive: subMenu.keepAlive,
      };
    };
    const doEditMainMenu = (mainMenu) => {
      state.formTitle = "编辑主菜单";
      state.isCreate = 2;
      state.modalVisible = true;
      state.editData = {
        parentId: mainMenu.parentId,
        id: mainMenu.id,
        name: mainMenu.name,
        menuCode: mainMenu.menuCode,
        linkUrl: mainMenu.linkUrl,
        iconUrl: mainMenu.iconUrl,
        path: mainMenu.path,
        sort: mainMenu.sort,
        memo: mainMenu.memo,
        enable: mainMenu.enable,
        keepAlive: mainMenu.keepAlive,
      };
    };
    const doEditSubMenu = (mainMenu, subMenu) => {
      state.formTitle = "编辑子菜单";
      state.isCreate = 5;
      state.modalVisible = true;
      state.mainInfo = {
        mid: mainMenu.id,
        name: mainMenu.name,
      };
      state.editData = {
        parentId: subMenu.parentId,
        id: subMenu.id,
        name: subMenu.name,
        menuCode: subMenu.menuCode,
        linkUrl: subMenu.linkUrl,
        iconUrl: subMenu.iconUrl,
        path: subMenu.path,
        sort: subMenu.sort,
        memo: subMenu.memo,
        enable: subMenu.enable,
        keepAlive: subMenu.keepAlive,
      };
    };
    const doDeleteMenu = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作讲永久删除菜单【" + record.name + "】，是否继续？"
        ),
        onOk() {
          if (record.parentId == 0) {
            initSubMenuList(record.id);
            if (state.subMenuList.length > 0) {
              message.info("此菜单关联有子菜单不能删除！！！");
            } else {
              deleteMenu(record.id).then((resp) => {
                if (resp) {
                  initMainMenuList();
                }
              });
            }
          } else {
            deleteMenu(record.id).then((resp) => {
              if (resp) {
                initSubMenuList(record.parentId);
              }
            });
          }
        },
        onCancel() {
          message.info("已取消删除菜单操作！！！");
        },
      });
    };
    const refData = toRefs(state);
    return {
      ...refData,
      initMainMenuList,
      initSubMenuList,
      onChange,
      handleModalVisible,
      doSetStatus, //设置菜单状态（1正常  0禁用）
      doSetCache, //设置页面缓存（1开启  0关闭）
      onSelectChange,
      doAddMainMenu,
      doAddSubMenu,
      doDetailMainMenu,
      doDetailSubMenu,
      doEditMainMenu,
      doEditSubMenu,
      doDeleteMenu,
    };
  },
};
</script>
<style lang='scss' scope>
.ant-card-head {
  height: 36px;
  padding: 0 10px;
}
.ant-card-head-wrapper {
  height: 36px;
}
.ant-card-body {
  padding: 4px;
}
.ant-card-head-title {
  height: 36px;
  color: #3805c4;
  padding: 6px;
}
.ant-collapse-header {
  height: 36px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 6px 6px 6px 36px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 4px;
}
.ant-table.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 6px 6px;
}
.ant-table.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 6px 6px;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>